import React, { useContext, useState, useCallback, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FaClipboardList, FaMapPin, FaUserCircle, FaCog } from 'react-icons/fa';
// import { FaUserCircle, FaCog } from 'react-icons/fa';
import BasicInfo from './steps/BasicInfo';
import LocationInfo from './steps/LocationInfo';
import TimingInfo from './steps/TimingInfo';
import ContactInfo from './steps/ContactInfo';
import AmenitiesInfo from './steps/AmenitiesInfo';
import StepIndicator from './StepIndicator';
import { initialFormData } from './data/initialFormData';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { GrConfigure } from "react-icons/gr";
import { CREATE_CHARGING_STATION, GET_CHARGING_STATION, UPDATE_CHARGING_STATION } from 'actions/chargingStation';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import moment from 'moment';


const StepForm = () => {

  const { t } = useTranslation();
  const { stationId } = useParams()
  const [currentStep, setCurrentStep] = useState(1);
  const [isStepValid, setIsStepValid] = useState(false);
  const profileData = useSelector((state) => state.profile.userProfile);
  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);
  const [formData, setFormData] = useState(() => {
    return chargingStationData && !_.isEmpty(chargingStationData)
      ? chargingStationData
      : initialFormData;
  });
  const currentUserName = _.get(profileData, 'name', '');
  const dispatch = useDispatch();
  const { navigateTo } = useContext(NavContext);


  const handleNext = () => {
    if (isStepValid) {
      setCurrentStep(currentStep + 1);
      console.log("button clicked")
      setIsStepValid(false); // Reset validation for the next step
    }
  };
  const getStationDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    if (stationId && _.isEmpty(chargingStationData)) {
      getStationDetail(stationId);
    }
  }, [stationId, chargingStationData]);

  useEffect(() => {
    if (chargingStationData && !_.isEmpty(chargingStationData)) {
      setFormData(chargingStationData);
    }
  }, [chargingStationData]);
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const addStation = useCallback((data) => {
    dispatch({
      type: CREATE_CHARGING_STATION.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/stations');
          }, 1000);
        }
      },
    });
  }, []);

  
  const handleSubmit = (isDraft) => {
    const fieldsToRemove = [
      'avg_rating',
      'rating_count',
      'total_chargers',
      'energy_consumed',
      'chargers_in_use',
      'average_charging_time',
      'contact_person',
      'number_of_sessions',
      'chargers',
      'tenant',
      'availableToUsers',
      'isFavorite',
      'createdAt',
      'updatedAt',
      'is_external_station',
      'total_ev_charged',
      'ocpiCredential',
      'external_location_id',
      'accountId',
      'percentage',
      'station_power_limit_activate'
    ];
    
    // Transform data first with proper mapping
    const transformedData = {
      ...formData,
      location: { 
        lat: String(formData.location?.lat || ''), 
        lng: String(formData.location?.lng || '') 
      },
      settings: {
        ...formData.settings,
        notification: formData.settings?.notification?.map(String) || [],
        energy_limits: String(_.get(formData, 'settings.energy_limits', ''))
      },
      tags: _.map(formData.tags, (item) => item && item.id),
      amenity: _.map(formData.amenity, (item) => item && item.id),
      pincode: formData.area ? formData.pincode : '',
      photos: _.map(formData?.photos, (item) => item),
      down_start_time: formData.down_start_time ? 
        moment(_.get(formData, 'down_start_time')).format('YYYY-MM-DD') : '',
      down_stop_time: formData.down_stop_time ? 
        moment(_.get(formData, 'down_stop_time')).format('YYYY-MM-DD') : '',
      contact_phone: formData.contact_phone ? 
        formData.contact_phone.toString() : '',
      razorpay: {
        accountId: _.get(formData, 'razorpay.accountId', ''),
        percentage: _.get(formData, 'razorpay.percentage', '')
      },
      countryCode: String(formData.countryCode || ''),
      contact_country_code: formData.contact_country_code,
      contact_name: formData.contact_name?.trim() || null,
      contact_email: formData.contact_email?.trim() || null,
      contact_address: formData.contact_address?.trim() || null,
      partner_logo: formData.partner_logo && formData?.partner_logo.toString()
    };

    // Clean data
    const cleanFormData = _.omit(transformedData, fieldsToRemove);
  
    const finalData = {
      ...cleanFormData,
      is_active: !isDraft
    };
  
    if (stationId && !_.isEmpty(chargingStationData)) {
      dispatch({
        type: UPDATE_CHARGING_STATION.REQUEST,
        payload: finalData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            setTimeout(() => {
              navigateTo(`/stations/${stationId}`);
            }, 1000);
          }
        },
      });
    } else {
      addStation(formData);
    }
  };

  const steps = [
    {
      id: 1,
      title: 'Basic Info',
      component: BasicInfo,
      icon: <FaClipboardList className="w-5 h-5" />,
    },
    {
      id: 2,
      title: 'Location',
      component: LocationInfo,
      icon: <FaMapPin className="w-5 h-5" />,
    },
    {
      id: 3,
      title: 'Configrations',
      component: TimingInfo,
      icon: <GrConfigure className="w-5 h-5" />,
    },
    {
      id: 4,
      title: 'Contact',
      component: ContactInfo,
      icon: <FaUserCircle className="w-5 h-5" />,
    },
    {
      id: 5,
      title: 'Amenities',
      component: AmenitiesInfo,
      icon: <FaCog className="w-5 h-5" />,
    },
  ];

  const CurrentStepComponent = steps.find((step) => step.id === currentStep)?.component;

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${stationId ? 'Edit' : 'Add'} ${t('header.chargingStation')}`} backTo={'/stations'} />
        <div className="page-content-wrapper scrollable">
          <div className="add-charging-station-page__main">
            <Card className="station-form--card">
              <div className="min-h-screen bg-gray-100 py-8">
                <div className="max-w-4xl mx-auto">
                  <div className="rounded-lg shadow-md p-6">
                    <StepIndicator steps={steps} currentStep={currentStep} />

                    <div className="mb-8">
                      {/* {CurrentStepComponent && <CurrentStepComponent formData={formData} setFormData={setFormData} />} */}

                      {CurrentStepComponent && (
                        <CurrentStepComponent 
                          formData={formData} 
                          setFormData={setFormData}
                          setIsStepValid={setIsStepValid}
                          chargingStationData={chargingStationData}
                        />
                      )}
                      </div>

                    <div className="save-draft-btn--block mt-5">
                      <Button
                        onClick={handlePrevious}
                        disabled={currentStep === 1}
                        className={` ${
                          currentStep === 1 ? ' save-btn cursor-not-allowed' : ' save-btn'
                        }`}
                      >
                        <FaChevronLeft/>
                        Previous
                      </Button>

                      {currentStep === steps.length ? (
                        <div className="space-x-4">
                          <Button onClick={() => handleSubmit(true)} className="save-btn draft--btn">
                            Save Draft
                          </Button>
                          <Button type="submit" onClick={() => handleSubmit(false)}  className="save-btn">
                            Save & Publish
                          </Button>
                        </div>
                      ) : (
                        <Button onClick={handleNext} className="flex items-center  save-btn ">
                          Next
                          <FaChevronRight />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default StepForm;
