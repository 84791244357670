import {
  STATION_UNIT_CONSUMPTION,
  B2B_STATION_CHARGING_HISTORY,
  B2C_STATION_CHARGING_HISTORY,
  JSON_REQUEST_RESPONSE_REPORT,
  WALLET_TRANSACTION_REPORT,
  ALL_DATA_REPORT,
  DOWNLOAD_ALL_DATA_REPORT,
  OCPI_BOOKING,
  DOWNLOAD_OCPI_BOOKING,
  MOST_VEHICLE_CHARGED_LIST,
  APP_USER_DETAILS_LIST,
  STATION_CHARGING_SUMMARY,
  PRIVATE_STATION_CHARGING_SUMMARY,
  PUBLIC_STATION_CHARGING_SUMMARY,
  BOOKING_HISTORY,
  USER_CHARGING_HISTORY,
  BOOKING_SUMMARY_REPORT,
  MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_STATION_CHARGING_SUMMARY,
  DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY,
  DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY,
  DOWNLOAD_METER_VALUES,
  DOWNLOAD_BOOKING_HISTORY,
  DOWNLOAD_USER_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_STATION_UNIT_CONSUMPTION,
  DOWNLOAD_CHARGING_HISTORY,
  CALENDAR_VIEW,
  DOWNLOAD_STATION_DOWNTIME_REPORT,
  STATION_DOWNTIME_REPORT,
  DOWNLOAD_B2B_STATION_CHARGING_HISTORY,
  AC_METER_READING_REPORT,
  DOWNLOAD_AC_METER_READING_REPORT,
  DOWNLOAD_APP_USER_DETAIL_REPORT,
  DOWNLOAD_JOB_VOUCHER,
  DOWNLOAD_GST_VOUCHER,
  INVOICE_REPORT,
  DOWNLOAD_INVOICE_REPORT,
  DOWNLOAD_BOOKING_SUMMARY_REPORT,
  DOWNLOAD_MOST_VEHICLE_CHARGED_LIST,
  PAYMENT_STATUS_REPORT,
  DOWNLOAD_PAYMENT_STATUS_REPORT,
  VEHICLE_CHARGING_SESSION,
  DOWNLOAD_VEHICLE_CHARGING_SESSION,
  FAULTY_BOOKING_REPORT,
  DOWNLOAD_FAULTY_BOOKING_REPORT,
  CHARGING_SESSION_REPORT,
  DOWNLOAD_CHARGING_SESSION_REPORT,
  PLANT_LOAD_FACTOR_REPORT,
  DOWNLOAD_PLANT_LOAD_FACTOR_REPORT,
  HMIL_CUSTOM_CHARGING_REPORT,
  DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT,
  CUSTOMER_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT,
  COUPON_USAGE_REPORT,
  DOWNLOAD_COUPON_USAGE_REPORT,
  COUPON_PERFORMANCE_REPORT,
  DOWNLOAD_COUPON_PERFORMANCE_REPORT,
  PARTNER_CHARGING_SUMMARY,
  DOWNLOAD_PARTNER_CHARGING_SUMMARY,
  GET_JOB_VOUCHER,
} from 'actions/dataReport';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* stationUnitConsumption(action) {
  try {
    const response = yield call(API.stationUnitConsumption, action.payload);
    yield put({ type: STATION_UNIT_CONSUMPTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_UNIT_CONSUMPTION.FAIL, payload: { error: e } });
  }
}

function* ocpiPartnerChargingReport(action) {
  try {
    const response = yield call(API.ocpiPartnerChargingReport, action.payload);
    yield put({ type: OCPI_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: OCPI_BOOKING.FAIL, payload: { error: e } });
  }
}

function* downloadocpiPartnerChargingReport(action) {
  try {
    const response = yield call(API.downloadocpiPartnerChargingReport, action.payload);
    yield put({ type: DOWNLOAD_OCPI_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_OCPI_BOOKING.FAIL, payload: { error: e } });
  }
}

function* downloadStationUnitConsumption(action) {
  try {
    const response = yield call(API.downloadStationUnitConsumption, action.payload);
    yield put({ type: DOWNLOAD_STATION_UNIT_CONSUMPTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_UNIT_CONSUMPTION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* b2bStationChargingHistory(action) {
  try {
    const response = yield call(API.b2bStationChargingHistory, action.payload);
    yield put({ type: B2B_STATION_CHARGING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: B2B_STATION_CHARGING_HISTORY.FAIL, payload: { error: e } });
  }
}

function* downloadB2BStationChargingHistory(action) {
  try {
    const response = yield call(API.downloadB2BStationChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_B2B_STATION_CHARGING_HISTORY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadChargingHistory(action) {
  try {
    const response = yield call(API.downloadChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_CHARGING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGING_HISTORY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}
function* b2cStationChargingHistory(action) {
  try {
    const response = yield call(API.b2cStationChargingHistory, action.payload);
    yield put({ type: B2C_STATION_CHARGING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: B2C_STATION_CHARGING_HISTORY.FAIL, payload: { error: e } });
  }
}

function* downloadB2CStationChargingHistory(action) {
  try {
    const response = yield call(API.downloadB2CStationChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* jsonRequestResponseReport(action) {
  try {
    const response = yield call(API.jsonRequestResponseReport, action.payload);
    yield put({ type: JSON_REQUEST_RESPONSE_REPORT.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: JSON_REQUEST_RESPONSE_REPORT.FAIL, payload: { error: e } });
  }
}

function* walletTransactionReport(action) {
  try {
    const response = yield call(API.walletTransactionReport, action.payload);
    yield put({ type: WALLET_TRANSACTION_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: WALLET_TRANSACTION_REPORT.FAIL, payload: { error: e } });
  }
}

function* alldatareport(action) {
  try {
    const response = yield call(API.alldatareport, action.payload);
    yield put({ type: ALL_DATA_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ALL_DATA_REPORT.FAIL, payload: { error: e } });
  }
}

function* HMILCustomChargingReport(action) {
  try {
    const response = yield call(API.HMILCustomChargingReport, action.payload);
    yield put({ type: HMIL_CUSTOM_CHARGING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: HMIL_CUSTOM_CHARGING_REPORT.FAIL, payload: { error: e } });
  }
}

function* DownloadHMILCustomChargingReport(action) {
  try {
    const response = yield call(API.downloadHMILCustomChargingReport, action.payload);
    yield put({ type: DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadWalletTransactionReport(action) {
  try {
    const response = yield call(API.downloadWalletTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_WALLET_TRANSACTION_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_WALLET_TRANSACTION_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadPlantLoadFactorReport(action) {
  try {
    const response = yield call(API.downloadPlantLoadFactorReport, action.payload);
    yield put({ type: DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadalldatareport(action) {
  try {
    const response = yield call(API.downloadalldatareport, action.payload);
    yield put({ type: DOWNLOAD_ALL_DATA_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_ALL_DATA_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* appUserDetailsList(action) {
  try {
    const response = yield call(API.appUserDetailsList, action.payload);
    yield put({ type: APP_USER_DETAILS_LIST.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: APP_USER_DETAILS_LIST.FAIL, payload: { error: e } });
  }
}

function* downloadappUserDetailList(action) {
  try {
    const response = yield call(API.downloadappUserDetailList, action.payload);
    yield put({ type: DOWNLOAD_APP_USER_DETAIL_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_APP_USER_DETAIL_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* bookingHistory(action) {
  try {
    const response = yield call(API.bookingHistory, action.payload);
    yield put({ type: BOOKING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: BOOKING_HISTORY.FAIL, payload: { error: e } });
  }
}

function* downloadBookingHistory(action) {
  try {
    const response = yield call(API.downloadBookingHistory, action.payload);
    yield put({ type: DOWNLOAD_BOOKING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BOOKING_HISTORY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* mostVehicleChargedList(action) {
  try {
    const response = yield call(API.mostVehicleChargedList, action.payload);
    yield put({ type: MOST_VEHICLE_CHARGED_LIST.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: MOST_VEHICLE_CHARGED_LIST.FAIL, payload: { error: e } });
  }
}

function* userChargingHistory(action) {
  try {
    const response = yield call(API.userChargingHistory, action.payload);
    yield put({ type: USER_CHARGING_HISTORY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: USER_CHARGING_HISTORY.FAIL, payload: { error: e } });
  }
}

function* downloadUserChargingHistory(action) {
  try {
    const response = yield call(API.downloadUserChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_USER_CHARGING_HISTORY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_USER_CHARGING_HISTORY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* stationChargingSummary(action) {
  try {
    const response = yield call(API.stationChargingSummary, action.payload);
    yield put({ type: STATION_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_CHARGING_SUMMARY.FAIL, payload: { error: e } });
  }
}

function* downloadStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_STATION_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_CHARGING_SUMMARY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* privateStationChargingSummary(action) {
  try {
    const response = yield call(API.privateStationChargingSummary, action.payload);
    yield put({ type: PRIVATE_STATION_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PRIVATE_STATION_CHARGING_SUMMARY.FAIL, payload: { error: e } });
  }
}

function* downloadPrivateStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadPrivateStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* publicStationChargingSummary(action) {
  try {
    const response = yield call(API.publicStationChargingSummary, action.payload);
    yield put({ type: PUBLIC_STATION_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PUBLIC_STATION_CHARGING_SUMMARY.FAIL, payload: { error: e } });
  }
}

function* downloadPublicStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadPublicStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadMeterValues(action) {
  try {
    const response = yield call(API.downloadMeterValues, action.payload);
    yield put({ type: DOWNLOAD_METER_VALUES.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_METER_VALUES.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* bookingSummaryReport(action) {
  try {
    const response = yield call(API.bookingSummaryReport, action.payload);
    yield put({ type: BOOKING_SUMMARY_REPORT.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: BOOKING_SUMMARY_REPORT.FAIL, payload: { error: e } });
  }
}

function* moneyTransactionPgReport(action) {
  try {
    const response = yield call(API.moneyTransactionPgReport, action.payload);
    yield put({ type: MONEY_TRANSACTION_PG_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: MONEY_TRANSACTION_PG_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadMoneyTransactionPGReport(action) {
  try {
    const response = yield call(API.downloadMoneyTransactionPGReport, action.payload);
    yield put({ type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* calendarView(action) {
  try {
    const response = yield call(API.calendarView, action.payload);
    yield put({ type: CALENDAR_VIEW.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CALENDAR_VIEW.FAIL, payload: { error: e } });
  }
}

function* stationDownTimeReport(action) {
  try {
    const response = yield call(API.stationDownTimeReport, action.payload);
    yield put({ type: STATION_DOWNTIME_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_DOWNTIME_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadStationDownTimeReport(action) {
  try {
    const response = yield call(API.downloadStationDownTimeReport, action.payload);
    yield put({ type: DOWNLOAD_STATION_DOWNTIME_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_DOWNTIME_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* acMeterReadingReport(action) {
  try {
    const response = yield call(API.acMeterReadingReport, action.payload);
    yield put({ type: AC_METER_READING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: AC_METER_READING_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadACMeterReadingReport(action) {
  try {
    const response = yield call(API.downloadACMeterReadingReport, action.payload);
    yield put({ type: DOWNLOAD_AC_METER_READING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_AC_METER_READING_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadJobVoucherReport(action) {
  try {
    const response = yield call(API.downloadJobVoucherInvoice, action.payload);
    yield put({ type: DOWNLOAD_JOB_VOUCHER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_JOB_VOUCHER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getJobVoucherReport(action) {
  try {
    const response = yield call(API.getJobVoucherInvoice, action.payload);
    yield put({ type: GET_JOB_VOUCHER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_JOB_VOUCHER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadGstVoucherReport(action) {
  try {
    const response = yield call(API.downloadGSTReport, action.payload);
    yield put({ type: DOWNLOAD_GST_VOUCHER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_GST_VOUCHER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* invoiceReport(action) {
  try {
    const response = yield call(API.invoiceReport, action.payload);
    yield put({ type: INVOICE_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: INVOICE_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadInvoiceReport(action) {
  try {
    const response = yield call(API.downloadInvoiceReport, action.payload);
    yield put({ type: DOWNLOAD_INVOICE_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_INVOICE_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadBookingSummaryReport(action) {
  try {
    const response = yield call(API.downloadBookingSummaryReport, action.payload);
    yield put({ type: DOWNLOAD_BOOKING_SUMMARY_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BOOKING_SUMMARY_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadMostVehicleChargedList(action) {
  try {
    const response = yield call(API.downloadMostVehicleChargedList, action.payload);
    yield put({ type: DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* paymentStatusReport(action) {
  try {
    const response = yield call(API.paymentStatusReport, action.payload);
    yield put({ type: PAYMENT_STATUS_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PAYMENT_STATUS_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadPaymentStatusReport(action) {
  try {
    const response = yield call(API.downloadPaymentStatusReport, action.payload);
    yield put({ type: DOWNLOAD_PAYMENT_STATUS_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PAYMENT_STATUS_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* vehicleChargingSession(action) {
  try {
    const response = yield call(API.vehicleChargingSession, action.payload);
    yield put({ type: VEHICLE_CHARGING_SESSION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: VEHICLE_CHARGING_SESSION.FAIL, payload: { error: e } });
  }
}

function* downloadVehicleChargingSession(action) {
  try {
    const response = yield call(API.downloadVehicleChargingSession, action.payload);
    yield put({ type: DOWNLOAD_VEHICLE_CHARGING_SESSION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_VEHICLE_CHARGING_SESSION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* faultyBookingReport(action) {
  try {
    const response = yield call(API.faultyBookingReport, action.payload);
    yield put({ type: FAULTY_BOOKING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FAULTY_BOOKING_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadFaultyBookingReport(action) {
  try {
    const response = yield call(API.downloadFaultyBookingReport, action.payload);
    yield put({ type: DOWNLOAD_FAULTY_BOOKING_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_FAULTY_BOOKING_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* chargingSessionReport(action) {
  try {
    const response = yield call(API.chargingSessionReport, action.payload);
    yield put({ type: CHARGING_SESSION_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGING_SESSION_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadChargingSessionReport(action) {
  try {
    const response = yield call(API.downloadChargingSessionReport, action.payload);
    yield put({ type: DOWNLOAD_CHARGING_SESSION_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGING_SESSION_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* plantLoadFactorReport(action) {
  try {
    const response = yield call(API.plantLoadFactorReport, action.payload);
    yield put({ type: PLANT_LOAD_FACTOR_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PLANT_LOAD_FACTOR_REPORT.FAIL, payload: { error: e } });
  }
}

function* customerWalletTransactionReport(action) {
  try {
    const response = yield call(API.customerWalletTransactionReport, action.payload);
    yield put({ type: CUSTOMER_WALLET_TRANSACTION_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CUSTOMER_WALLET_TRANSACTION_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadCustomerWalletTransactionReport(action) {
  try {
    const response = yield call(API.downloadCustomerWalletTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.FAIL, payload: { error: e } });
  }
}

function* couponUsageReport(action) {
  try {
    const response = yield call(API.couponUsageReport, action.payload);
    yield put({ type: COUPON_USAGE_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: COUPON_USAGE_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadCouponUsageReport(action) {
  try {
    const response = yield call(API.downloadCouponUsageReport, action.payload);
    yield put({ type: DOWNLOAD_COUPON_USAGE_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_COUPON_USAGE_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* couponPerformanceReport(action) {
  try {
    const response = yield call(API.couponPerformanceReport, action.payload);
    yield put({ type: COUPON_PERFORMANCE_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: COUPON_PERFORMANCE_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadCouponPerformanceReport(action) {
  try {
    const response = yield call(API.downloadCouponPerformanceReport, action.payload);
    yield put({ type: DOWNLOAD_COUPON_PERFORMANCE_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_COUPON_PERFORMANCE_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* partnerChargingSummary(action) {
  try {
    const response = yield call(API.partnerChargingSummary, action.payload);
    yield put({ type: PARTNER_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PARTNER_CHARGING_SUMMARY.FAIL, payload: { error: e } });
  }
}

function* downloadPartnerChargingSummary(action) {
  try {
    const response = yield call(API.downloadPartnerChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_PARTNER_CHARGING_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PARTNER_CHARGING_SUMMARY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}


function* dataReport() {
  yield takeLatest(STATION_UNIT_CONSUMPTION.REQUEST, stationUnitConsumption);
  yield takeLatest(OCPI_BOOKING.REQUEST, ocpiPartnerChargingReport);
  yield takeLatest(DOWNLOAD_OCPI_BOOKING.REQUEST, downloadocpiPartnerChargingReport);
  yield takeLatest(B2B_STATION_CHARGING_HISTORY.REQUEST, b2bStationChargingHistory);
  yield takeLatest(B2C_STATION_CHARGING_HISTORY.REQUEST, b2cStationChargingHistory);
  yield takeLatest(JSON_REQUEST_RESPONSE_REPORT.REQUEST, jsonRequestResponseReport);
  yield takeLatest(WALLET_TRANSACTION_REPORT.REQUEST, walletTransactionReport);
  yield takeLatest(ALL_DATA_REPORT.REQUEST, alldatareport);
  yield takeLatest(HMIL_CUSTOM_CHARGING_REPORT.REQUEST, HMILCustomChargingReport);
  yield takeLatest(BOOKING_HISTORY.REQUEST, bookingHistory);
  yield takeLatest(APP_USER_DETAILS_LIST.REQUEST, appUserDetailsList);
  yield takeLatest(MOST_VEHICLE_CHARGED_LIST.REQUEST, mostVehicleChargedList);
  yield takeLatest(USER_CHARGING_HISTORY.REQUEST, userChargingHistory);
  yield takeLatest(STATION_CHARGING_SUMMARY.REQUEST, stationChargingSummary);
  yield takeLatest(PRIVATE_STATION_CHARGING_SUMMARY.REQUEST, privateStationChargingSummary);
  yield takeLatest(PUBLIC_STATION_CHARGING_SUMMARY.REQUEST, publicStationChargingSummary);
  yield takeLatest(BOOKING_SUMMARY_REPORT.REQUEST, bookingSummaryReport);
  yield takeLatest(MONEY_TRANSACTION_PG_REPORT.REQUEST, moneyTransactionPgReport);
  yield takeLatest(DOWNLOAD_STATION_CHARGING_SUMMARY.REQUEST, downloadStationChargingSummary);
  yield takeLatest(DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY.REQUEST, downloadPrivateStationChargingSummary);
  yield takeLatest(DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY.REQUEST, downloadPublicStationChargingSummary);
  yield takeLatest(DOWNLOAD_METER_VALUES.REQUEST, downloadMeterValues);
  yield takeLatest(DOWNLOAD_BOOKING_HISTORY.REQUEST, downloadBookingHistory);
  yield takeLatest(DOWNLOAD_USER_CHARGING_HISTORY.REQUEST, downloadUserChargingHistory);
  yield takeLatest(DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST, downloadB2CStationChargingHistory);
  yield takeLatest(DOWNLOAD_CHARGING_HISTORY.REQUEST, downloadChargingHistory);
  yield takeLatest(DOWNLOAD_B2B_STATION_CHARGING_HISTORY.REQUEST, downloadB2BStationChargingHistory);
  yield takeLatest(DOWNLOAD_MONEY_TRANSACTION_PG_REPORT.REQUEST, downloadMoneyTransactionPGReport);
  yield takeLatest(DOWNLOAD_WALLET_TRANSACTION_REPORT.REQUEST, downloadWalletTransactionReport);
  yield takeLatest(DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.REQUEST, downloadPlantLoadFactorReport);
  yield takeLatest(DOWNLOAD_ALL_DATA_REPORT.REQUEST, downloadalldatareport);
  yield takeLatest(DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT.REQUEST, DownloadHMILCustomChargingReport);
  yield takeLatest(DOWNLOAD_STATION_UNIT_CONSUMPTION.REQUEST, downloadStationUnitConsumption);
  yield takeLatest(CALENDAR_VIEW.REQUEST, calendarView);
  yield takeLatest(STATION_DOWNTIME_REPORT.REQUEST, stationDownTimeReport);
  yield takeLatest(DOWNLOAD_STATION_DOWNTIME_REPORT.REQUEST, downloadStationDownTimeReport);
  yield takeLatest(AC_METER_READING_REPORT.REQUEST, acMeterReadingReport);
  yield takeLatest(DOWNLOAD_AC_METER_READING_REPORT.REQUEST, downloadACMeterReadingReport);
  yield takeLatest(DOWNLOAD_APP_USER_DETAIL_REPORT.REQUEST, downloadappUserDetailList);
  yield takeLatest(DOWNLOAD_JOB_VOUCHER.REQUEST, downloadJobVoucherReport);
  yield takeLatest(GET_JOB_VOUCHER.REQUEST, getJobVoucherReport);
  yield takeLatest(DOWNLOAD_GST_VOUCHER.REQUEST, downloadGstVoucherReport);
  yield takeLatest(INVOICE_REPORT.REQUEST, invoiceReport);
  yield takeLatest(DOWNLOAD_INVOICE_REPORT.REQUEST, downloadInvoiceReport);
  yield takeLatest(DOWNLOAD_BOOKING_SUMMARY_REPORT.REQUEST, downloadBookingSummaryReport);
  yield takeLatest(DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.REQUEST, downloadMostVehicleChargedList);
  yield takeLatest(PAYMENT_STATUS_REPORT.REQUEST, paymentStatusReport);
  yield takeLatest(DOWNLOAD_PAYMENT_STATUS_REPORT.REQUEST, downloadPaymentStatusReport);
  yield takeLatest(VEHICLE_CHARGING_SESSION.REQUEST, vehicleChargingSession);
  yield takeLatest(DOWNLOAD_VEHICLE_CHARGING_SESSION.REQUEST, downloadVehicleChargingSession);
  yield takeLatest(DOWNLOAD_FAULTY_BOOKING_REPORT.REQUEST, downloadFaultyBookingReport);
  yield takeLatest(FAULTY_BOOKING_REPORT.REQUEST, faultyBookingReport);
  yield takeLatest(CHARGING_SESSION_REPORT.REQUEST, chargingSessionReport);
  yield takeLatest(DOWNLOAD_CHARGING_SESSION_REPORT.REQUEST, downloadChargingSessionReport);
  yield takeLatest(PLANT_LOAD_FACTOR_REPORT.REQUEST, plantLoadFactorReport);
  yield takeLatest(CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST, customerWalletTransactionReport);
  yield takeLatest(DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST, downloadCustomerWalletTransactionReport);
  yield takeLatest(PARTNER_CHARGING_SUMMARY.REQUEST, partnerChargingSummary);
  yield takeLatest(DOWNLOAD_PARTNER_CHARGING_SUMMARY.REQUEST, downloadPartnerChargingSummary);
  yield takeLatest(COUPON_USAGE_REPORT.REQUEST, couponUsageReport);
  yield takeLatest(DOWNLOAD_COUPON_USAGE_REPORT.REQUEST, downloadCouponUsageReport);
  yield takeLatest(COUPON_PERFORMANCE_REPORT.REQUEST, couponPerformanceReport);
  yield takeLatest(DOWNLOAD_COUPON_PERFORMANCE_REPORT.REQUEST, downloadCouponPerformanceReport);
}
export default dataReport;
