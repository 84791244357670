import { createActionTypes } from 'utils/action/actionTypes';

export const STATION_UNIT_CONSUMPTION = createActionTypes('STATION_UNIT_CONSUMPTION');
export const OCPI_BOOKING = createActionTypes('OCPI_BOOKING');
export const DOWNLOAD_OCPI_BOOKING = createActionTypes('DOWNLOAD_OCPI_BOOKING');
export const CHARGING_HISTORY = createActionTypes('CHARGING_HISTORY');
export const CHARGING_HISTORY_REPORT = createActionTypes('CHARGING_HISTORY_REPORT');
export const B2B_STATION_CHARGING_HISTORY = createActionTypes('B2B_STATION_CHARGING_HISTORY');
export const B2C_STATION_CHARGING_HISTORY = createActionTypes('B2C_STATION_CHARGING_HISTORY');
export const JSON_REQUEST_RESPONSE_REPORT = createActionTypes('JSON_REQUEST_RESPONSE_REPORT');
export const WALLET_TRANSACTION_REPORT = createActionTypes('WALLET_TRANSACTION_REPORT');
export const HMIL_CUSTOM_CHARGING_REPORT = createActionTypes('HMIL_CUSTOM_CHARGING_REPORT');
export const DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT = createActionTypes('DOWNLOAD_HMIL_CUSTOM_CHARGING_REPORT');
export const ALL_DATA_REPORT = createActionTypes('ALL_DATA_REPORT');
export const ADD_BILL_DETAILS = createActionTypes('ADD_BILL_DETAILS');
export const MONEY_TRANSACTION_PG_REPORT = createActionTypes('MONEY_TRANSACTION_PG_REPORT');
export const APP_USER_DETAILS_LIST = createActionTypes('APP_USER_DETAILS_LIST');
export const MOST_VEHICLE_CHARGED_LIST = createActionTypes('MOST_VEHICLE_CHARGED_LIST');
export const BOOKING_HISTORY = createActionTypes('BOOKING_HISTORY');
export const USER_CHARGING_HISTORY = createActionTypes('USER_CHARGING_HISTORY');
export const STATION_CHARGING_SUMMARY = createActionTypes('STATION_CHARGING_SUMMARY');
export const PRIVATE_STATION_CHARGING_SUMMARY = createActionTypes('PRIVATE_STATION_CHARGING_SUMMARY');
export const PUBLIC_STATION_CHARGING_SUMMARY = createActionTypes('PUBLIC_STATION_CHARGING_SUMMARY');
export const BOOKING_SUMMARY_REPORT = createActionTypes('BOOKING_SUMMARY_REPORT');
export const STATION_DOWNTIME_REPORT = createActionTypes('STATION_DOWNTIME_REPORT');
export const DOWNLOAD_STATION_CHARGING_SUMMARY = createActionTypes('DOWNLOAD_STATION_CHARGING_SUMMARY');
export const DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY = createActionTypes('DOWNLOAD_PUBLIC_STATION_CHARGING_SUMMARY');
export const DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY = createActionTypes('DOWNLOAD_PRIVATE_STATION_CHARGING_SUMMARY');
export const DOWNLOAD_METER_VALUES = createActionTypes('DOWNLOAD_METER_VALUES');
export const DOWNLOAD_BOOKING_HISTORY = createActionTypes('DOWNLOAD_BOOKING_HISTORY');
export const DOWNLOAD_USER_CHARGING_HISTORY = createActionTypes('DOWNLOAD_USER_CHARGING_HISTORY');
export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY = createActionTypes('DOWNLOAD_B2C_STATION_CHARGING_HISTORY');
export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT = createActionTypes('DOWNLOAD_MONEY_TRANSACTION_PG_REPORT');
export const DOWNLOAD_WALLET_TRANSACTION_REPORT = createActionTypes('DOWNLOAD_WALLET_TRANSACTION_REPORT');
export const DOWNLOAD_ALL_DATA_REPORT = createActionTypes('DOWNLOAD_ALL_DATA_REPORT');
export const DOWNLOAD_STATION_UNIT_CONSUMPTION = createActionTypes('DOWNLOAD_STATION_UNIT_CONSUMPTION');
export const CALENDAR_VIEW = createActionTypes('CALENDAR_VIEW');
export const DOWNLOAD_STATION_DOWNTIME_REPORT = createActionTypes('DOWNLOAD_STATION_DOWNTIME_REPORT');
export const DOWNLOAD_CHARGING_HISTORY = createActionTypes('DOWNLOAD_CHARGING_HISTORY');
export const DOWNLOAD_B2B_STATION_CHARGING_HISTORY = createActionTypes('DOWNLOAD_B2B_STATION_CHARGING_HISTORY');
export const AC_METER_READING_REPORT = createActionTypes('AC_METER_READING_REPORT');
export const DOWNLOAD_AC_METER_READING_REPORT = createActionTypes('DOWNLOAD_AC_METER_READING_REPORT');
export const DOWNLOAD_APP_USER_DETAIL_REPORT = createActionTypes('DOWNLOAD_APP_USER_DETAIL_REPORTDOWNLOAD_APP_USER_DETAIL_REPORT');
export const DOWNLOAD_JOB_VOUCHER = createActionTypes('DOWNLOAD_JOB_VOUCHER');
export const GET_JOB_VOUCHER = createActionTypes('GET_JOB_VOUCHER');
export const DOWNLOAD_GST_VOUCHER = createActionTypes('DOWNLOAD_GST_VOUCHER');
export const INVOICE_REPORT = createActionTypes('INVOICE_REPORT');
export const DOWNLOAD_INVOICE_REPORT = createActionTypes('DOWNLOAD_INVOICE_REPORT');
export const DOWNLOAD_BOOKING_SUMMARY_REPORT = createActionTypes('DOWNLOAD_BOOKING_SUMMARY_REPORT');
export const DOWNLOAD_MOST_VEHICLE_CHARGED_LIST = createActionTypes('DOWNLOAD_MOST_VEHICLE_CHARGED_LIST');
export const CAPACITY_UTILISATION = createActionTypes('CAPACITY_UTILISATION');
export const DOWNLOAD_CAPACITY_DATA = createActionTypes('DOWNLOAD_CAPACITY_DATA');
export const PAYMENT_STATUS_REPORT = createActionTypes('PAYMENT_STATUS_REPORT');
export const DOWNLOAD_PAYMENT_STATUS_REPORT = createActionTypes('DOWNLOAD_PAYMENT_STATUS_REPORT');
export const VEHICLE_CHARGING_SESSION = createActionTypes('VEHICLE_CHARGING_SESSION');
export const DOWNLOAD_VEHICLE_CHARGING_SESSION = createActionTypes('DOWNLOAD_VEHICLE_CHARGING_SESSION');
export const CHARGING_SESSION_REPORT = createActionTypes('CHARGING_SESSION_REPORT');
export const DOWNLOAD_CHARGING_SESSION_REPORT = createActionTypes('DOWNLOAD_CHARGING_SESSION_REPORT');
export const FAULTY_BOOKING_REPORT = createActionTypes('FAULTY_BOOKING_REPORT');
export const DOWNLOAD_FAULTY_BOOKING_REPORT = createActionTypes('DOWNLOAD_FAULTY_BOOKING_REPORT');
export const PLANT_LOAD_FACTOR_REPORT = createActionTypes('PLANT_LOAD_FACTOR_REPORT');
export const DOWNLOAD_PLANT_LOAD_FACTOR_REPORT = createActionTypes('DOWNLOAD_PLANT_LOAD_FACTOR_REPORT');
export const CUSTOMER_WALLET_TRANSACTION_REPORT = createActionTypes('CUSTOMER_WALLET_TRANSACTION_REPORT');
export const DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT = createActionTypes('DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT');
export const PARTNER_CHARGING_SUMMARY = createActionTypes('PARTNER_CHARGING_SUMMARY');
export const DOWNLOAD_PARTNER_CHARGING_SUMMARY = createActionTypes('DOWNLOAD_PARTNER_CHARGING_SUMMARY');
export const COUPON_USAGE_REPORT = createActionTypes('COUPON_USAGE_REPORT');
export const DOWNLOAD_COUPON_USAGE_REPORT = createActionTypes('DOWNLOAD_COUPON_USAGE_REPORT');
export const COUPON_PERFORMANCE_REPORT = createActionTypes('COUPON_PERFORMANCE_REPORT');
export const DOWNLOAD_COUPON_PERFORMANCE_REPORT = createActionTypes('DOWNLOAD_COUPON_PERFORMANCE_REPORT');
