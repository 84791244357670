import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_AREA, FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { Form, Formik } from 'formik';
import { FETCH_ELECTRICITY_SUPPLIER } from 'actions/electricitySupplier';
import * as Yup from 'yup';


const LocationInfo = ({ formData, setFormData, setIsStepValid, chargingStationData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  // Retrieve required data from Redux store
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const allArea = useSelector((state) => state.area.areas);
  const countryPage = useSelector((state) => state.country.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const statePage = useSelector((state) => state.state.page);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityPage = useSelector((state) => state.city.page);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const areaPage = useSelector((state) => state.area.page);
  const areaTotalPage = useSelector((state) => state.area.totalPages);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY.REQUEST, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_AREA.REQUEST, payload: data });
  }, []);

  const getAllElectricitySupplierByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_ELECTRICITY_SUPPLIER.REQUEST, payload: data });
  }, []);

  useEffect(() => { 
    getAllCountry()
  },[]);
  
  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required('Address is required')
      .min(5, 'Address must be at least 5 characters long'),
    location: Yup.object().shape({
      lat: Yup.number()
        .typeError('Latitude must be a number')
        .required('Latitude is required')
        .min(-90, 'Latitude must be between -90 and 90')
        .max(90, 'Latitude must be between -90 and 90'),
      lng: Yup.number()
        .typeError('Longitude must be a number')
        .required('Longitude is required')
        .min(-180, 'Longitude must be between -180 and 180')
        .max(180, 'Longitude must be between -180 and 180'),
    }),
    country: Yup.string().required('Country is required'),
    countryCode: Yup.string()
      .required('Country code is required').trim(),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    area: Yup.string().required('Area is required'),
    pincode: Yup.string()
      .required('Pincode is required'),
  });
  
  useEffect(() => {
    if (!_.isEmpty(chargingStationData)) {
      getStateByCountry({ country_name: chargingStationData.country, limit: 999 });
      getCityByState({ state_name: chargingStationData.state });
      getAreaByCity({ city_name: chargingStationData.city });
    }
  }, [chargingStationData])
  return (
    <Formik
    initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={() => {}}
    >
    
    {({ errors, touched, setFieldValue, validateForm, values }) => {
 React.useEffect(() => {
  const checkValidity = async () => {
    const validationResult = await validateForm();
    const isFormValid = Object.keys(validationResult).length === 0;
    console.log('Validation Result:', validationResult);
    console.log('Form Values:', values);
    setIsStepValid(isFormValid);
  };
  
  checkValidity();
}, [values, validateForm]);
     return (
     <Form className='space-y-4'>
      <h5 className="text-xl font-semibold mb-4">Location Information</h5>
       <Row>
         <Col lg={12}>
         <Col lg={12}>
         <TextInput
          isRequired
          label={t('addStationForm.address')}
          name="address"
          value={formData.address}
          onChange={(e) => {
            setFieldValue('address', e.target.value);
            setFormData({ ...formData, address: e.target.value })
          }}
          error={errors.address }
        />
    </Col>
         </Col>
         <Col lg={6} md={12}>
           <TextInput
             isRequired
             label={t('addStationForm.latitude')}
             name="location.lat"
             value={formData.location.lat}
             onChange={(e) => {
              setFieldValue('location.lat', e.target.value);
              setFormData({
                ...formData,
                location: { ...formData.location, lat: e.target.value },
              })
            }}
             error={errors.location?.lat}
           />
         </Col>
         <Col lg={6} md={12}>
           <TextInput
             isRequired
             label={t('addStationForm.longitude')}
             name="location.lng"
             value={formData.location.lng}
             onChange={(e) => {
              setFieldValue('location.lng', e.target.value);
              setFormData({
                ...formData,
                location: { ...formData.location, lng: e.target.value },
              })
            }}
             error={errors.location?.lng}
           />
         </Col>
         <Col lg={6} md={12}>
           <Row>
             <Col>
             <Select
                isRequired
                label={t('addStationForm.country')}
                options={allCountries.map((item) => ({
                  label: item.name,
                  value: item.name
                }))}
                placeholder={t('placeHolder.selectCountry')}
                name="country"
                value={formData.country}
                onMenuScrollDown={true}
                getDataOnScrollDown={getAllCountry}
                page={countryPage}
                totalPage={countryTotalPage}
                onChange={(e) => {
                  const selectedCountry = e.target ? e.target.value : e;
                  const countryCode = String(_.find(allCountries, { name: selectedCountry })?.country_code || '');
                  setFieldValue('country', selectedCountry);
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    country: selectedCountry,
                    countryCode: countryCode, // Ensure it's a string
                    state: '',
                    city: '',
                    area: '',
                    pincode: ''
                  }));
                  getStateByCountry({ country_name: selectedCountry });
                }}
                error={errors.country}
              />
            </Col>
             <Col>
               <TextInput
                 isRequired
                 disabled
                 label={t('addStationForm.countryCode')}
                 name="countryCode"
                 value={formData.countryCode}
                 error={errors.countryCode && touched.countryCode ? errors.countryCode : null}
               />
             </Col>
           </Row>
         </Col>
         <Col lg={6} md={12}>
           <Select
             isRequired
             label={t('addStationForm.state')}
             options={_.map(allState, (item) => {
               return { label: item.name, value: item.name };
             })}
             placeholder={t('placeHolder.selectState')}
             name="state"
             value={formData.state}
             onMenuScrollDown={true}
             getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: _.get(formData, 'country') })}
             page={statePage}
             totalPage={stateTotalPage}
             onChange={(val) => {
               getCityByState({ state_name: val });
               const currentState = _.find(allState, { name: val });
               setFormData((prevFormData) => ({
                 ...prevFormData,
                 state: currentState?.name || '',
                 city: '',
                 area: '',
                 pincode: '',
                 settings: {
                   ...prevFormData.settings,
                   DISCOM_provider: '',
                 },
               }));
             }}
             error={errors.state}
           />
         </Col>
         <Col lg={6} md={12}>
         <Select
            isRequired
            label={t('addStationForm.city')}
            options={
              formData.state &&
              _.map(allCity, (item) => ({
                label: item.name,
                value: item.name
              }))
            }
            placeholder={t('placeHolder.selectCity')}
            name="city"
            value={formData.city}
            onMenuScrollDown={true}
            getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: _.get(formData, 'state') })}
            page={cityPage}
            totalPage={cityTotalPage}
            onChange={(val) => {
              if (!val) return;
              
              getAreaByCity({ city_name: val });
              const currentCity = _.find(allCity, { name: val });
              
              if (currentCity?.id) {
                getAllElectricitySupplierByCity({ city: currentCity.id });
              }

              setFormData((prevFormData) => ({
                ...prevFormData,
                city: currentCity?.name || '',
                area: '',
                pincode: '',
              }));
            }}
            error={errors.city}
          />
         </Col>
         <Col lg={6} md={12}>
           <Select
             isRequired
             label={t('addStationForm.area')}
             options={
               formData.city &&
               _.map(allArea, (item) => {
                 return { label: item.name, value: item.name };
               })
             }
             placeholder={t('placeHolder.selectArea')}
             name="area"
             value={formData.area}
             onMenuScrollDown={true}
             getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: _.get(formData, 'city') })}
             page={areaPage}
             totalPage={areaTotalPage}
             onChange={(val) => {
               const currentArea = _.find(allArea, { name: val });
               setFormData((prevFormData) => ({
                 ...prevFormData,
                 area: currentArea?.name || '',
                 pincode: currentArea?.postal_code || '',
               }));
             }}
             error={errors.area}
           />
         </Col>
         <Col lg={6} md={12}>
           <TextInput
             isRequired
             disabled
             label={t('addStationForm.pincode')}
             name="pincode"
             value={formData.pincode}
             onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}
             error={errors.pincode}
           />
         </Col>
       </Row>
    </Form>)}
  }
    </Formik>
  );
};

LocationInfo.propTypes = {
  formData: PropTypes.object.isRequired, 
  setFormData: PropTypes.func.isRequired, 
  setIsStepValid: PropTypes.func.isRequired, 
  chargingStationData: PropTypes.func.isRequired
};

export default LocationInfo;

