import { createActionTypes } from 'utils/action/actionTypes';

export const GET_SMC_PAYMENT_GATEWAY = createActionTypes('GET_SMC_PAYMENT_GATEWAY');

export const GET_BRV_REPORTS = createActionTypes('GET_BRV__REPORTS');

export const GET_JV_REPORTS = createActionTypes('GET_JV__REPORTS');

export const DOWNLOAD_BRV_REPORT = createActionTypes('DOWNLOAD_BRV_REPORT');

export const DOWNLOAD_JV_REPORT = createActionTypes('DOWNLOAD_JV_REPORT');

export const UPDATE_VOUCHER_NUMBER = createActionTypes('UPDATE_VOUCHER_NUMBER');
