import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TbWalletOff, TbShieldOff, TbChargingPile } from 'react-icons/tb';
// import { TbWalletOff, TbBatteryCharging, TbShieldOff, TbChargingPile } from 'react-icons/tb';
import { SlCalender } from 'react-icons/sl';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
import { MdClose } from 'react-icons/md';
// Memoized components for better performance
const DetailCard = memo(({ title, children, className = '' }) => (
  <div className={`card shadow-sm ${className}`}>
    {title && (
      <div className="card-header bg-transparent">
        <h6 className="mb-0">{title}</h6>
      </div>
    )}
    <div className="card-body">{children}</div>
  </div>
));
DetailCard.displayName = 'DetailCard';

const InfoField = memo(({ label, value }) => (
  <div className="mb-3">
    <small className="text-muted d-block">{label}</small>
    <span className="fw-medium">{value}</span>
  </div>
));
InfoField.displayName = 'InfoField';
// Constants
const ALERT_ICONS = {
  'Low Wallet Balance': TbWalletOff,
  // 'Start Charging Issue': TbBatteryCharging,
  'Charging Interruption': TbChargingPile,
  'Attempting on an Already Booked Slot': SlCalender,
  'Incorrect Attempt': TbShieldOff,
};

const ACTION_BUTTONS = {
  'Low Wallet Balance': ['Charger Details', 'User Details'],
  'Charging Interruption': ['Charger Details', 'User Details', 'Booking Details'],
  'Attempting on an Already Booked Slot': ['Charger Details', 'User Details', 'Booking Details'],
  'Incorrect Attempt': ['Charger Details'],
};

const AlertItem = memo(({ alert }) => {
  const [modalState, setModalState] = useState({ show: false, view: null });
  const chargingStationData = alert.stationDetails;

  const handleViewDetails = useCallback(
    (type) => {
      setModalState({ show: true, view: type });
    },
    [alert]
  );

  const handleClose = useCallback(() => {
    setModalState({ show: false, view: null });
  }, []);

  const renderChargerDetails = useCallback(() => {
    const { chargerDetails } = alert;
    if (!chargerDetails) return null;

    return (
      <div className="charger-details p-2">
        <div className="row g-4">
          <DetailCard className="border-0 bg-light">
            <div className="d-flex justify-content-between align-items-center">
              <InfoField label="OCPP ID" value={chargerDetails.charger_id} />
              <span
                className={`badge ${
                  chargerDetails.charger_status === 'Available' ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'
                }`}
              >
                {chargerDetails.charger_status}
              </span>
            </div>
          </DetailCard>

          <DetailCard>
            {[
              {
                label: 'Charging Station',
                value: chargingStationData?.name,
              },
              {
                label: 'Contact Name',
                value: chargingStationData?.contact_name,
              },
              {
                label: 'Address',
                value: [chargingStationData?.address, chargingStationData?.area, chargingStationData?.city].filter(Boolean).join(', '),
              },
              {
                label: 'Phone Number',
                value: chargingStationData?.contact_phone && `+${chargingStationData?.countryCode}${chargingStationData?.contact_phone}`,
              },
              {
                label: 'Email Address',
                value: chargingStationData?.contact_email,
              },
            ]
              .filter((field) => field.value)
              .map(({ label, value }) => (
                <InfoField key={label} label={label} value={value} />
              ))}
          </DetailCard>

          <DetailCard title="Connected Plugs">
            <div className="list-group list-group-flush">
              {chargerDetails.plugs?.map((plug) => (
                <div key={plug._id} className="list-group-item d-flex gap-4 align-items-center border-0 px-0">
                  <div className="d-flex align-items-center">
                    <div
                      className={`rounded-circle me-2 ${plug.status === 'Available' ? 'bg-success' : 'bg-danger'}`}
                      style={{ width: '8px', height: '8px' }}
                    />
                    <span>{plug.name}</span>
                  </div>
                  <span className={`badge ${plug.status === 'Available' ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}`}>
                    {plug.status}
                  </span>
                </div>
              ))}
            </div>
          </DetailCard>
        </div>
      </div>
    );
  }, [alert.chargerDetails, chargingStationData]);

  const renderUserDetails = useCallback(() => {
    const { userDetails } = alert;
    if (!userDetails) return null;

    return (
      <div className="user-details p-2">
        <div className="row g-4">
          <DetailCard className="bg-light border-0">
            <div className="d-flex align-items-center">
              <div
                className="rounded-circle bg-primary d-flex align-items-center justify-content-center text-white"
                style={{ width: '48px', height: '48px' }}
              >
                <span className="fs-4">{userDetails.name ? userDetails.name.charAt(0) : 'G'}</span>
              </div>
              <div className="ms-3">
                <h5 className="mb-1">{userDetails?.name ?? 'Guest User'}</h5>
                <p className="text-muted mb-0">{userDetails.email}</p>
              </div>
            </div>
          </DetailCard>

          <div className="col-md-6">
            <DetailCard title="Contact Information">
              <InfoField label="Phone Number" value={`+${userDetails.country_code}${userDetails.phone}`} />
              <InfoField label="Email Address" value={userDetails.email} />
            </DetailCard>
          </div>

          <div className="col-md-6">
            <DetailCard title="Account Details">
              <InfoField label="Wallet Balance" value={`₹ ${roundOfDigit(userDetails.wallet_balance, 2)}`} />
              <InfoField label="Last Active" value={moment(userDetails.lastActiveSession).format('DD MMM YYYY, h:mm A')} />
            </DetailCard>
          </div>
        </div>
      </div>
    );
  }, [alert.userDetails]);

  const renderBookingDetails = useCallback(() => {
    const { bookingDetails } = alert;
    if (!bookingDetails) return null;

    return (
      <div className="booking-details p-2">
        <div className="row g-4">
          <DetailCard className="border-0 bg-light">
            <div className="d-flex justify-content-between align-items-center">
              <InfoField label="Booking ID" value={bookingDetails._id || bookingDetails.id} />
              <span className={`badge ${bookingDetails.status === 'completed' ? 'bg-success' : 'bg-warning'}`}>{bookingDetails.status}</span>
            </div>
          </DetailCard>

          <DetailCard title="Booking Information">
            <div className="row g-3">
              {[
                { label: 'Schedule Date & Time', value: moment(bookingDetails.schedule_datetime).format('DD MMM YYYY, h:mm A') },
                { label: 'Transaction ID', value: bookingDetails.transaction_id || 'N/A' },
                { label: 'Connector ID', value: bookingDetails.connectorId },
                { label: 'ID Tag', value: bookingDetails.idTag },
                { label: 'Time Taken', value: bookingDetails.time_taken ? `${Math.floor(bookingDetails.time_taken / 60000)} minutes` : 'N/A' },
                { label: 'Stop Reason', value: bookingDetails.stop_reason || 'N/A' },
              ].map(({ label, value }) => (
                <div key={label} className="col-md-6">
                  <InfoField label={label} value={value} />
                </div>
              ))}
            </div>
          </DetailCard>

          <DetailCard title="Meter Readings">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="p-3 bg-light rounded">
                  <InfoField label="Start Reading" value={`${bookingDetails.meterstart || '0'} kWh`} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-3 bg-light rounded">
                  <InfoField label="Stop Reading" value={`${bookingDetails.meterstop || '0'} kWh`} />
                </div>
              </div>
            </div>
          </DetailCard>
        </div>
      </div>
    );
  }, [alert.bookingDetails]);

  const AlertIcon = ALERT_ICONS[alert.alertType];
  const isBlinking = alert.color === 'red' ? 'blinking' : alert.color === 'yellow' ? 'blinking-yellow' : '';

  return (
    <>
      <div className={`alert-item alert-${alert.color}`}>
        <span className="alert-icon">{AlertIcon && <AlertIcon />}</span>
        <div className="alert-content">
          <span className={`alert-message ${isBlinking}`}>{alert.message}</span>
          <span className="alert-date">{moment(alert.createdAt).format('dddd, DD MMMM, h:mm A')}</span>
          <div className="alert-btn-modal">
            {ACTION_BUTTONS[alert.alertType]?.map(
              (buttonType) =>
                (buttonType !== 'User Details' || alert.userId) && (
                  <Button key={buttonType} onClick={() => handleViewDetails(buttonType)}>
                    {buttonType}
                  </Button>
                )
            )}
          </div>
        </div>
      </div>

      <Modal show={modalState.show} onHide={handleClose} centered className="alert-modal">
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          <div className='alert-modal-header'>
          <Modal.Title>{modalState.view}</Modal.Title>
          <button  onClick={handleClose}>
            <MdClose />
          </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {modalState.view === 'Charger Details' && renderChargerDetails()}
          {modalState.view === 'User Details' && renderUserDetails()}
          {modalState.view === 'Booking Details' && renderBookingDetails()}
        </Modal.Body>
      </Modal>
    </>
  );
});
AlertItem.displayName = 'AlertItem';

AlertItem.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    alertType: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    color: PropTypes.string,
    chargerDetails: PropTypes.object,
    userDetails: PropTypes.object,
    bookingDetails: PropTypes.object,
    stationDetails: PropTypes.object,
    userId: PropTypes.string,
  }).isRequired,
};

DetailCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

InfoField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default AlertItem;
