import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { useTranslation } from 'react-i18next';

const ContactInfo = ({ formData, setFormData, setIsStepValid }) => {
  const { t } = useTranslation();

  useEffect(() => { 
    setIsStepValid(true);
    // Initialize contact_phone if empty
    if (!formData.contact_phone) {
      setFormData(prev => ({
        ...prev,
        contact_phone: ''
      }));
    }
  }, [setIsStepValid]);

  const handlePhoneChange = (value) => {
    // Ensure value is string and handle empty cases
    const phoneValue = value || '';
    setFormData(prev => ({
      ...prev,
      contact_phone: phoneValue
    }));
  };

  return (
    <div className="space-y-4">
      <h5 className="text-xl font-semibold mb-4">{t('addStationForm.owner/contactPerson')}</h5>
      <Row>
        <Col lg={6} md={12}>
          <TextInput
            label={t('addStationForm.name')}
            name="contact_name"
            value={formData.contact_name || ''}
            onChange={(e) => setFormData({ ...formData, contact_name: e.target.value })}
          />
        </Col>

        <Col lg={6} md={12}>
          <PhoneNumberInput
            label={t('addStationForm.phoneNumber')}
            name="contact_phone"
            value={formData.contact_phone || ''}
            onChange={handlePhoneChange}
          />
        </Col>

        <Col lg={6} md={12}>
          <TextInput
            label={t('addStationForm.emailAddress')}
            name="contact_email"
            value={formData.contact_email || ''}
            onChange={(e) => setFormData({ ...formData, contact_email: e.target.value })}
          />
        </Col>

        <Col lg={6} md={12}>
          <TextInput
            label={t('addStationForm.officeAddress')}
            name="contact_address"
            value={formData.contact_address || ''}
            onChange={(e) => setFormData({ ...formData, contact_address: e.target.value })}
          />
        </Col>
      </Row>
    </div>
  );
};

ContactInfo.propTypes = {
  formData: PropTypes.shape({
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
    contact_email: PropTypes.string,
    contact_address: PropTypes.string
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  setIsStepValid: PropTypes.func.isRequired
};

export default ContactInfo;