import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import CheckBox from 'components/inputs/CheckBox';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FETCH_CHARGING_STATION_ACCESS_TYPE } from 'actions/stationAccessType';
import { FETCH_TAGS } from 'actions/tagmanagement';

const statuses = [
  { label: 'Unavailable', value: 'Unavailable' },
  { label: 'Available', value: 'Available' },
  { label: 'Under Maintenance', value: 'Under Maintenance' },
  { label: 'Coming Soon', value: 'Coming Soon' },
  { label: 'In Use', value: 'In Use' },
  { label: 'Under Commissioning', value: 'Under Commissioning' },
];

const BasicInfo = ({ formData, setFormData, setIsStepValid }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stationAccessTypeList = useSelector((state) => state.stationAccessType.stationAccessTypes);
  const allTags = useSelector((state) => state.tagmanagement.tags);
  // const supportApp = useSelector((state) => _.get(state.profile.userProfile, 'tenant.support_cz_app_booking', ''));
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name is requried")),
    access_type: Yup.string().required(t("access type is required")),
    tags: Yup.array().min(1, t("tags are required")).required(t("tags are required")),
    status: Yup.string().required(t("status is required")),
    description: Yup.string().optional(),
    home_charging_code: Yup.string().when("does_support_home_charging", {
      is: true,
      then: Yup.string().required(t("charigng code is required")),
    }),
  });

  const getStationAccessType = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGING_STATION_ACCESS_TYPE.REQUEST, payload: data });
  }, []);

  const getAllTags = useCallback((data = {}) => {
    dispatch({ type: FETCH_TAGS.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    // getAllAmenity({ limit: 30 });
    // getAllCountry();
    getStationAccessType();
    getAllTags({ limit: 999 });
    
  }, []);


  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={() => {}}
    >
      {({ errors, touched, setFieldValue, validateForm, values }) => {
        React.useEffect(() => {
          const checkValidity = async () => {
            const validationResult = await validateForm();
            const isFormValid = Object.keys(validationResult).length === 0;
            setIsStepValid(isFormValid);
          };
          
          checkValidity();
        }, [values, validateForm]);

        return (
          <Form className="space-y-4">
            <h5 className="text-md font-semibold mb-4">{t('addStationForm.basicInformations')}</h5>
            <Row>
              <Col lg={6} md={12}>
                <TextInput
                  isRequired
                  label={t('addStationForm.nameofChargingStation')}
                  placeholder={t('placeHolder.chargingStationName')}
                  name="name"
                  value={values.name || ''}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                    setFormData(prev => ({...prev, name: e.target.value}));
                  }}
                  error={errors.name && touched.name ? null : errors.name}
                />
              </Col>

              <Col lg={6} md={12}>
              
              <Select
                isRequired
                label={t('addStationForm.chargingStationAccessType')}
                options={_.map(stationAccessTypeList, item => ({
                  label: item.name,
                  value: item.name
                }))}
                placeholder={t('placeHolder.selectType')}
                name="access_type"
                value={values.access_type || ''}
                onChange={(selectedOption) => {
                  setFieldValue('access_type', selectedOption);
                  setFormData(prev => ({...prev, access_type: selectedOption}));
                }}
                error={errors.access_type && touched.access_type ? null : errors.access_type}
              />
              </Col>

              <Col lg={6} md={12}>
              <Select
                isRequired
                label={t('filters.tags')}
                isMulti
                options={_.map(allTags, item => ({
                  label: item.name,
                  value: item.id
                }))}
                placeholder={t('placeHolder.selectTags')}
                name="tags"
                // value={formData?.tags?.map((data) => data?.id) || []}
                value={formData?.tags?.map(t => t.id || t) || []}
                onChange={(selectedOptions) => {
                  setFieldValue('tags', selectedOptions);
                  setFormData(prev => ({...prev, tags: selectedOptions}));
                }}
                error={errors.tags && touched.tags ? null : errors.tags}
              />
              </Col>

              <Col lg={6} md={12}>
                <TextInput
                  as="textarea"
                  rows="1"
                  inputClass="scrollable"
                  label={t('addStationForm.description')}
                  name="description"
                  value={values.description || ''}
                  onChange={(e) => {
                    setFieldValue('description', e.target.value);
                    setFormData(prev => ({...prev, description: e.target.value}));
                  }}
                  placeholder={t('placeHolder.description')}
                />
              </Col>

              <Col lg={6} md={12}>
              <Select
                isRequired
                label={t('addStationForm.status')}
                options={statuses}
                placeholder={t('placeHolder.status')}
                name="status"
                value={values.status || ''}
                onChange={(selectedOption) => {
                  setFieldValue('status', selectedOption);
                  setFormData(prev => ({...prev, status: selectedOption}));
                }}
                error={errors.status && touched.status ? null : errors.status}
              />
              </Col>

              {values.access_type === 'Private' && (
                <Col>
                  <Row>
                    <Col lg={6} md={12}>
                      <div className="show-on-mobile-field-main--block">
                        <div className="show-on-mobile-checkbox-input__block">
                          <label htmlFor="does_support_home_charging" className="show-on-mobile--label">
                            {t('addStationForm.doesSupportHomeCharging')}
                          </label>
                          <CheckBox
                            name="does_support_home_charging"
                            checked={values.does_support_home_charging || false}
                            onChange={(e) => {
                              setFieldValue('does_support_home_charging', e.target.checked);
                              setFormData(prev => ({...prev, does_support_home_charging: e.target.checked}));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    {values.does_support_home_charging && (
                      <Col lg={6} md={12}>
                        <TextInput
                          label={t('addStationForm.homeChargingCode')}
                          placeholder={t('placeHolder.homeChargingCode')}
                          name="home_charging_code"
                          value={values.home_charging_code || ''}
                          onChange={(e) => {
                            setFieldValue('home_charging_code', e.target.value);
                            setFormData(prev => ({...prev, home_charging_code: e.target.value}));
                          }}
                          error={errors.homeChargingCode && touched.homeChargingCode ? null : errors.homeChargingCode}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              )}

              <Col lg={6} md={12}>
                <div className="notification-setting__block">
                  <div id="notification_allow" className="notification-setting--inner">
                    <div className="notification--item">
                      <Select
                        value={_.get(values, 'settings.notification')}
                        onChange={(e) => {
                          setFieldValue('settings.notification', e.target.value);
                          setFormData(prev => ({
                            ...prev,
                            settings: {
                              ...prev.settings,
                              notification: e.target.value,
                            },
                          }));
                        }}
                        options={[
                          { value: 'Email', label: t('addStationForm.email') },
                          { value: 'SMS', label: t('addStationForm.sms') },
                        ]}
                        isMulti={true}
                        placeholder={t('Select Notification Method')}
                        label={t('addStationForm.notificationAllowAs')}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

BasicInfo.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  setIsStepValid: PropTypes.func.isRequired,
  chargingStationData: PropTypes.object.isRequired,
};

export default BasicInfo;
