import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { B2C_STATION_CHARGING_HISTORY, DOWNLOAD_B2C_STATION_CHARGING_HISTORY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment-timezone';
import Button from 'components/inputs/Button';
import { millisecondsToHourMinute, roundOfDigit } from 'components/common/utils';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { IoSearchOutline } from 'react-icons/io5';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';

const Report = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [cityLimit, setCityLimit] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const page = useSelector((state) => state.b2cStationChargingHistory.page);
  const totalData = useSelector((state) => state.b2cStationChargingHistory.total);
  const limit = useSelector((state) => state.b2cStationChargingHistory.limit);
  const totalPages = useSelector((state) => state.b2cStationChargingHistory.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment().tz(countryTimezone).startOf('year'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));
  const userProfileStations = profileData.charging_stations;

  const chargingHistoryReportList = useSelector((state) => state.b2cStationChargingHistory.b2cStationChargingHistory);
  const isLoader = useSelector((state) => state.b2cStationChargingHistory.isLoading);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const b2cStationChargingHistory = useCallback(
    (value) => {
      if (searchText) {
        const b2cStationChargingHistoryData = {
          ...value,
          type: 'Public',
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          status: 'completed',
          search: searchText,
        };
        dispatch({
          type: B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: b2cStationChargingHistoryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          type: 'Public',
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          status: 'completed',
        };
        dispatch({
          type: B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        partyId: _.get(ocpiData, 'partyId'),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
        };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
        };
        b2cStationChargingHistory(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        partyId: _.get(ocpiData, 'partyId'),
        charging_station: userProfileStations,
      };
      b2cStationChargingHistory(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        partyId: _.get(ocpiData, 'partyId'),
      };
      b2cStationChargingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    b2cStationChargingHistory({});
  };

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      b2cStationChargingHistory(data);
    } else {
      b2cStationChargingHistory();
    }
  }, [searchText]);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && b2cStationChargingHistory(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        b2cStationChargingHistory(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && b2cStationChargingHistory(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        b2cStationChargingHistory(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = {
            ...advanceFilterData,
            charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            state: '',
            city: '',
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
            is_ocpi_based_booking: true,
            partyId: _.get(ocpiData, 'partyId'),
          };
          b2cStationChargingHistory(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        b2cStationChargingHistory(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          search: searchText,
          page: page.selected + 1,
        };
        b2cStationChargingHistory(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          page: page.selected + 1,
        };
        b2cStationChargingHistory(data);
      } else {
        const data = {
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          page: page.selected + 1,
        };
        b2cStationChargingHistory(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );

  // Download Excel Functionality

  const downloadB2CStationChargingHistory = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
        };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          is_ocpi_based_booking: true,
          partyId: _.get(ocpiData, 'partyId'),
          report: 'charginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Charging History Report'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        partyId: _.get(ocpiData, 'partyId'),
        report: 'charginghistory',
        type: 'Public',
        status: 'completed',
        charging_station: userProfileStations,
      };
      dispatch({
        type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Charging History Report'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        is_ocpi_based_booking: true,
        partyId: _.get(ocpiData, 'partyId'),
        report: 'charginghistory',
        type: 'Public',
        status: 'completed',
      };
      dispatch({
        type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Charging History Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadB2CStationChargingHistory();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const totalStateData = useSelector((state) => state.state.total);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const totalCityData = useSelector((state) => state.cityByState.total);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { country: '', state: '', city: '', charging_station: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE.REQUEST, payload: { ...data, deleted: true } });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: stationData });
  }, []);

  useEffect(() => {
    if (showAdvanceFilterPopup) {
      getStateByCountry({ country_name: country, limit: totalStateData });
    }
    if (cityLimit) {
      getCityByStateName({ state_names: state, limit: totalCityData });
    }
  }, [country, state, totalStateData, totalCityData, cityLimit]);

  useEffect(() => {
    getAllCountry();
  }, []);

  return (
    <>
      <div className="data-report--main">
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="data-report__inner">
            <div className="data-report__box">
              <Row className="data-report__row">
                <Col
                  xl={`${showSearchBar ? 'auto' : 'atuo'}`}
                  md={`${showSearchBar ? 'auto' : 'auto'}`}
                  className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                >
                  {showSearchBar ? search : searchIcon}
                </Col>
                <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                  <div className="data-report-date_picker">
                    <DateTimePicker
                      onChangeOfStartDate={(item) => setStartDate(item)}
                      onChangeOfEndDate={(item) => setEndDate(item)}
                      initialValueOfStartDate={moment().startOf('year')}
                      initialValueOfEndDate={moment(new Date()).endOf('day')}
                    />
                  </div>
                </Col>
                <Col xl={'auto'} md={'auto'}>
                  <div className="report-search-box" onClick={dateFilter}>
                    <Button className="report-search-button">{t('button.search')}</Button>
                  </div>
                </Col>
                <Col xl={'auto'} md={'auto'}>
                  <div className="report-search-excel-block">
                    <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                      <Button className="report-search-button">
                        <FaFilter className="hide-lap" />
                        <span>{t('button.advancedFilter')}</span>
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                  {isLoading ? (
                    <div className="loader--block">
                      <Spinner as="span" animation="border" size="sm" role="status" />
                    </div>
                  ) : (
                    <div className="report-excel-icon">
                      <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="data-report__table">
              <div className="table-responsive">
                <table className="record-list-table" id="table-to-xls">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationName')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.chargerID')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.partyId')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.transactionId')}</span>
                          <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.chargingDateTime')}</span>
                          <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionUnitConsumption')}</span>
                          <span className="ico" onClick={() => handleSorting('energy_consumed', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionDuration')}</span>
                          <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.reasonToStop')}</span>
                          <span className="ico" onClick={() => handleSorting('stop_reason', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.makeModel')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoader ? (
                      <tr>
                        <td colSpan={9} className="border-0">
                          <div className="loader--block">
                            <Skeleton height={15} width={100} />
                          </div>
                        </td>
                      </tr>
                    ) : _.isEmpty(chargingHistoryReportList) ? (
                      <tr>
                        <td colSpan={10} className="border-0">
                          <div className="empty-data-block">{t('dataReport.NoChargingHistoryData')}</div>
                        </td>
                      </tr>
                    ) : (
                      !(isLoader || _.isUndefined(isLoader)) &&
                      _.map(chargingHistoryReportList, (item, index) => {
                        const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                        const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart'))
                          ? ''
                          : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);
                        const serial_num = limit * (page - 1) + index;
                        return (
                          <tr key={`b2c-station-charging-history-${serial_num}`}>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                            <td>
                              {isLoader ? (
                                <Skeleton height={15} width={100} />
                              ) : (
                                <>
                                  {_.get(item, 'charger.charger_id', '')} ({_.get(connectorData, 'name', '0')})
                                </>
                              )}
                            </td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '')}</td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '')}</td>
                            <td>
                              {isLoader ? (
                                <Skeleton height={15} width={100} />
                              ) : item.schedule_datetime ? (
                                moment(item.schedule_datetime).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                ''
                              )}
                            </td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : unitConsumed}</td>
                            <td>
                              {isLoader ? <Skeleton height={15} width={100} /> : millisecondsToHourMinute(_.get(item, 'time_taken'))}:
                              {moment.duration(_.get(item, 'time_taken')).seconds()}
                            </td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                            <td>
                              {isLoader ? (
                                <Skeleton height={15} width={100} />
                              ) : (
                                <>
                                  {_.get(item, 'vehicle.make', '')} ({_.get(item, 'vehicle.model', '')})
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(chargingHistoryReportList) && (
              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={(pageVal) => handlePageClick(pageVal)}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            )}
          </div>
        </SkeletonTheme>
      </div>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.charging_station && delete values['charging_station'];
              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All') {
                const b2cStationChargingHistoryData = {
                  ...values,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.state === 'All' && values.charging_station === 'All') {
                const b2cStationChargingHistoryData = {
                  ...values,
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.city === 'All' && values.charging_station === 'All') {
                const b2cStationChargingHistoryData = {
                  ...values,
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.state === 'All' && values.city === 'All') {
                const b2cStationChargingHistoryData = { ...values, state: '', city: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.city === 'All') {
                const b2cStationChargingHistoryData = { ...values, city: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.state === 'All') {
                const b2cStationChargingHistoryData = { ...values, state: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.charging_station === 'All') {
                const b2cStationChargingHistoryData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else {
                b2cStationChargingHistory(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label="Country"
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder="Select Country"
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      // setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label="State"
                    options={
                      !changeState
                        ? values.country &&
                          !_.isEmpty(allState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder="Select State"
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label="City"
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                          !_.isEmpty(allCityByState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allCityByState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select City"
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getAllStation({ city_array: '' });
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`city`, '');
                      } else {
                        getAllStation({ city_array: val });
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue('city', val);
                      }
                      setFieldValue(`charging_station`, '');
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label="Station"
                      options={
                        !changeStation
                          ? !_.isEmpty(_.get(values, 'city')) &&
                            !_.isEmpty(stationList) && [
                              { label: 'All', value: 'All' },
                              ..._.map(stationList, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(_.get(values, 'city')) &&
                            !_.isEmpty(_.get(values, 'state')) &&
                            values.country &&
                            !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      b2cStationChargingHistory();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    Clear
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default Report;
