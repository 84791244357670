import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsDownload, BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_ALL_DATA_REPORT, ALL_DATA_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import { roundOfDigit, formatAmount } from 'components/common/utils';
import { GET_CHARGER_LOG_GRAPH } from 'actions/chargerLogGraph';
import { FaInfoCircle } from 'react-icons/fa';
import ChargerGraphPopup from 'components/general/ChargerGraphPopup';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';


const alldatareport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);
  // const [changeWalletType, setChangeWalletType] = useState(false);
  // const [changePaymentType, setChangePaymentType] = useState(false);
  const alldatareportList = useSelector((state) => state.alldatareport.alldatareport);
  const isLoader = useSelector((state) => state.alldatareport.isLoading);
  const page = useSelector((state) => state.alldatareport.page);
  const totalData = useSelector((state) => state.alldatareport.total);
  const limit = useSelector((state) => state.alldatareport.limit);
  const totalPages = useSelector((state) => state.alldatareport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  const [showGraphPopup, setShowGraphPopup] = useState(false);
  const isLoadingGraph = useSelector((state) => state.chargerLogGraph.isLoading);
  const chargerGraph = useSelector((state) => state.chargerLogGraph.graphDetail);
  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment().tz(countryTimezone).startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day'));

  const alldatareport = useCallback(
    (value) => {
      if (searchText) {
        const alldatareportData = {
          ...value,
          search: searchText,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: ALL_DATA_REPORT.REQUEST,
          payload: alldatareportData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: ALL_DATA_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        dispatch({
          type: ALL_DATA_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          wallet_type: '',
          type: '',
        };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else if (advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else if (advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
        };
        alldatareport(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
        charging_station: userProfileStations,
      };
      alldatareport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz(countryTimezone).utc(),
        to: moment(endDate).tz(countryTimezone).utc(),
      };
      alldatareport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    alldatareport({});
  };

  const getChargerLogGraph = useCallback(
    (id) => {
      dispatch({
        type: GET_CHARGER_LOG_GRAPH.REQUEST,
        payload: id,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            setShowGraphPopup(true);
          } else if (_.get(res, 'status') === 404) {
            setShowGraphPopup(false);
          }
        },
      });
    },
    [dispatch, isLoadingGraph]
  );

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      alldatareport(data);
    } else {
      alldatareport();
    }
  }, []);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && alldatareport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        alldatareport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && alldatareport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        alldatareport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
          const filterData = {
            ...advanceFilterData,
            charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            wallet_type: '',
            type: '',
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else if (advanceFilterData.type === 'All') {
          const filterData = { ...advanceFilterData, type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else if (advanceFilterData.wallet_type === 'All') {
          const filterData = { ...advanceFilterData, wallet_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz(countryTimezone).utc(),
            to: moment(endDate).tz(countryTimezone).utc(),
          };
          alldatareport(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        alldatareport(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        alldatareport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz(countryTimezone).utc(),
          to: moment(endDate).tz(countryTimezone).utc(),
          page: page.selected + 1,
        };
        alldatareport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        alldatareport(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );
  // Download Excel Functionality

  const downloadalldatareport = useCallback(() => {
    let filterData = { ...advanceFilterData };

    // Check if charging station filter needs to be adjusted
    if (advanceFilterData.charging_station === 'All' && !_.isEmpty(userProfileStations)) {
      filterData = { ...filterData, charging_station: userProfileStations };
    }

    const data = {
      ...filterData,
      excel: true,
      from: moment(startDate).tz(countryTimezone).utc(),
      to: moment(endDate).tz(countryTimezone).utc(),
      report: 'alldatareport',
      status: 'completed',
    };

    dispatch({
      type: DOWNLOAD_ALL_DATA_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        if (res && _.get(res, 'status') === 200) {
          fileDownload(res.data, `${'All Data Report'}.xlsx`);
        }
      },
    });
  }, [startDate, endDate, advanceFilterData, userProfileStations]);

  const downloadFile = () => {
    downloadalldatareport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);

  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { charging_station: '', wallet_type: '', type: '', charger_id: '' };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION.REQUEST, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  // const allWalletType = [
  //   { label: 'Book', value: 'Book' },
  //   { label: 'Booking Cancellation', value: 'Booking Cancellation' },
  //   { label: 'CMS Credit', value: 'CMS CREDIT' },
  //   { label: 'Refund', value: 'Refund' },
  //   { label: 'Online Credit', value: 'WalletTopup' },
  //   { label: 'Cash Back', value: 'Cashback' },
  //   { label: 'Extra Unit Consumed', value: 'ExtraUnits' },
  // ];

  // const allPaymentType = [
  //   { label: 'Debit', value: 'debit' },
  //   { label: 'Credit', value: 'credit' },
  // ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.alldatareport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                        style={{ display: 'none' }}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.submit')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.srNo')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.userName')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.phoneNo')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.country')}</span>
                                <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.state')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.city')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.stationName')}</span>
                              </div>
                            </th>

                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.stationTag')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.ocppId')}</span>
                              </div>
                            </th>

                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.externalCharger')}</span>
                              </div>
                            </th> */}

                            {/* 
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.connectorName')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.capacity')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.chargerVendor')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.cpoName')}</span>
                              </div>
                            </th>

                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.partyId')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.transactionId')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.rfidIdTag')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.bookingType')}</span>
                              </div>
                            </th>

                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.bookingMethod')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.bookingScheduleDateTime')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionStartDateTime')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionStopDateTime')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionDuration')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.startSoC')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.stopSoC')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.meterStartReading')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.meterStopReading')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.minTemp')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.maxTemp')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionUnitConsumption')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.reasonToStop')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sessionStopBy')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.energyRate')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.companyGst')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.discount')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.cashback')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.netAmount')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.igstAmount')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.cgstAmount')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.sgstAmount')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.taxableAmount')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.totalAmountPaid')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.invoiceNo')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.invoiceType')}</span>
                              </div>
                            </th>
                            {/* 
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.irn')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.ackNo')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.ackDt')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.makeModel')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.vehicleRegistrationNo')}</span>
                              </div>
                            </th>

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.customerName')}</span>
                              </div>
                            </th>

                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.ocpiCustomer')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.mobileNo')}</span>
                              </div>
                            </th>

                            {/* <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.customerGst')}</span>
                              </div>
                            </th> */}

                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.rating')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.initiatedBy')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.fleetName')}</span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('alldatareport.graphInfo')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(alldatareportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('addalldatareport.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(alldatareportList, (item, key) => {
                              _.find(item && item.booking ? _.get(item, 'booking.charger.plugs', '') : '', {
                                connector_id: item && item.booking ? item.booking.connectorId : '',
                              });
                              const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart'))
                                ? ''
                                : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 2);
                              const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                              const capacity = item.charger.oem?.plugs.find((plug) => plug.name === _.get(connectorData, 'name'));
                              function calculateTimeDifference(start, end) {
                                const startTime = moment(start);
                                const stopTime = moment(end);

                                const timeDiffInMS = stopTime - startTime;

                                const seconds = moment.duration(timeDiffInMS).seconds();
                                const minutes = moment.duration(timeDiffInMS).minutes();
                                const hours = Math.trunc(moment.duration(timeDiffInMS).asHours());

                                if (Math.sign(timeDiffInMS) === -1) {
                                  return `-${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                } else {
                                  return `${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`;
                                }
                              }

                              function calculateActualDiscount(status, chargerBooking) {
                                if (
                                  chargerBooking.invoice?.coupon &&
                                  chargerBooking.invoice?.coupon_discount?.discount_type === 'direct_discount'
                                ) {
                                  return roundOfDigit(chargerBooking.invoice?.coupon_discount?.discount_value, 2);
                                } else if (
                                  chargerBooking.invoice?.coupon &&
                                  chargerBooking.invoice?.coupon_discount?.discount_type === 'charge_coins'
                                ) {
                                  return roundOfDigit(chargerBooking.invoice?.coupon_discount?.discount_value, 2);
                                } else if (chargerBooking.invoice?.chargecoins_used) {
                                  return roundOfDigit(chargerBooking.invoice?.chargecoins_used, 2);
                                } else {
                                  return '-';
                                }
                              }

                              const vehicle = _.get(item, 'vehicle.vin_num')
                                ? _.get(item, 'vehicle.vin_num')
                                : _.get(item, 'vehicle.rto_no')
                                  ? _.get(item, 'vehicle.rto_no')
                                  : '-';

                              const serial_num = limit * (page - 1) + key;
                              return (
                                <>
                                  <tr key={`wallet-transaction-report-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCustomer.user_name', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCustomer.phone', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.country', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.state', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.city', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '-')}</td>
                                    {/* <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        _.get(item, 'charger.charging_station.tags', []).map((tag, index) => (
                                          <React.Fragment key={index}>
                                            {tag}
                                            {index < item.charger.charging_station.tags.length - 1 && ', '}
                                          </React.Fragment>
                                        ))
                                      )}
                                    </td> */}
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '-')}</td>
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.is_external_charge', '-')}</td> */}
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.plugs.connector_id', '-')}</td> */}
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        _.get(capacity, 'rating', '-').toLowerCase().replace('kw', '')
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.oem.vendor.name', '-')}</td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'cpo_name', '-' )}
                                    </td>
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '-')}</td> */}
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'booking_type', '-')}</td>
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, '-', '-')}</td> */}
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.schedule_datetime ? (
                                        moment(item.schedule_datetime).format('DD/MM/YYYY HH:mm:ss')
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.booking_start ? (
                                        moment(item.booking_start).format('DD/MM/YYYY HH:mm:ss')
                                      ) : (
                                        '-'
                                      )}
                                    </td>

                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.booking_stop ? (
                                        moment(item.booking_stop).format('DD/MM/YYYY HH:mm:ss')
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>{calculateTimeDifference(item.booking_start, item.booking_stop)}</>
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StartSoC', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'StopSoC', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstart', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'meterstop', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'min_temperature', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'max_temperature', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : unitConsumed}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'session_stop_by', '-')}</td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.price_per_unit', '-'))}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.gst_used', '-')}</td>
                                    
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : calculateActualDiscount(item.status, item)}</td>
                                    
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.cashback', '-'))}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'invoice') ? (
                                        _.get(item, 'invoice.service_charge') ? (
                                          formatAmount(_.get(item, 'invoice.service_charge', '-'))
                                        ) : (
                                          formatAmount(_.get(item, 'invoice.subtotal', '-'))
                                        )
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'invoice.gst_type') === 'IGST' ? (
                                        formatAmount(_.get(item, 'invoice.gst', '-'))
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'invoice.gst_type') === 'SGST' ? (
                                        formatAmount(_.get(item, 'invoice.gst', '-') / 2)
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'invoice.gst_type') === 'SGST' ? (
                                        formatAmount(_.get(item, 'invoice.gst', '-') / 2)
                                      ) : (
                                        '-'
                                      )}
                                    </td>

                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.subtotal', '-'))}</td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.total_amount', '-'))}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : formatAmount(_.get(item, 'invoice.invoice_no', '-'))}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'invoice.einvoice_generated') ? (
                                        'E-Invoice'
                                      ) : _.get(item, 'customer_user_booked.role') !== 'fleet_member' && !_.get(item, 'is_vehicle_based_booking') ? (
                                        'Tax-Invoice'
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.einvoice_generated', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.irn', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, '-', '-')}</td> */}
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>
                                          {_.get(item, 'vehicle.make', '')} ({_.get(item, 'vehicle.model', '')})
                                        </>
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : vehicle}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.name', '-')}</td>
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, '-', '-')}</td> */}
                                    {/* <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.customer_gst', '-')}</td> */}
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.phone', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'review.ratings', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiemspFleet.initiated_by', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiemspFleet.initiator_name', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <FaInfoCircle
                                          onClick={() => {
                                            getChargerLogGraph(item.id);
                                          }}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(alldatareportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.wallet_type && delete values['wallet_type'];
              !values.type && delete values['type'];
              if (values.charging_station === 'All' && values.wallet_type === 'All' && values.type === 'All') {
                const alldatareportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  charger_id: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  wallet_type: '',
                  type: '',
                };
                alldatareport(alldatareportData);
              } else if (values.charging_station === 'All' && values.wallet_type === 'All') {
                const alldatareportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  wallet_type: '',
                };
                alldatareport(alldatareportData);
              } else if (values.charging_station === 'All' && values.type === 'All') {
                const alldatareportData = {
                  ...values,
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  type: '',
                };
                alldatareport(alldatareportData);
              } else if (values.wallet_type === 'All' && values.type === 'All') {
                const alldatareportData = { ...values, wallet_type: '', type: '' };
                alldatareport(alldatareportData);
              } else if (values.charging_station === 'All') {
                const alldatareportData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                alldatareport(alldatareportData);
              } else if (values.wallet_type === 'All') {
                const alldatareportData = { ...values, wallet_type: '' };
                alldatareport(alldatareportData);
              } else if (values.type === 'All') {
                const alldatareportData = { ...values, type: '' };
                alldatareport(alldatareportData);
              } else {
                alldatareport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station.name, value: station.id };
                                }),
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                            ? !_.isEmpty(allStation) && [
                                { label: 'All', value: 'All' },
                                ..._.map(allStation, (station) => {
                                  return { label: station.name, value: station.id };
                                }),
                              ]
                            : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                        setFieldValue('charger_id', ''); // Reset charger_id when charging_station changes
                      }}
                    />
                    <Select
                      label={t('Charger Id')}
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation && values.charging_station && values.charging_station !== 'All'
                            ? _.flatMap(
                                stationList.filter((station) => values.charging_station.includes(station.id)),
                                (station) =>
                                  station.chargers && station.chargers.length > 0
                                    ? station.chargers
                                        .filter((charger) => !charger.is_deleted)
                                        .map((charger) => ({
                                          label: charger.charger_id,
                                          value: charger.id,
                                        }))
                                    : []
                              )
                            : []
                          : !changeStation && values.charging_station && values.charging_station !== 'All'
                            ? _.flatMap(
                                allStation.filter((station) => values.charging_station.includes(station.id)),
                                (station) =>
                                  station.chargers && station.chargers.length > 0
                                    ? station.chargers
                                        .filter((charger) => !charger.is_deleted)
                                        .map((charger) => ({
                                          label: charger.charger_id,
                                          value: charger.id,
                                        }))
                                    : []
                              )
                            : []
                      }
                      placeholder={t('Charger Id')}
                      name="charger_id"
                      isMulti
                      value={values.charger_id}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.isEmpty(val)) {
                          setFieldValue(`charger_id`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charger_id', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      alldatareport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
      <ChargerGraphPopup show={showGraphPopup} onHide={() => setShowGraphPopup(false)} graphData={chargerGraph} isLoading={isLoadingGraph} />
    </>
  );
};

export default alldatareport;
